<template>
  <div style="position: relative; top: 40%; text-align: center">
    <h1>Redirect ...</h1>
    <h1>Please wait</h1>
  </div>
</template>

<script>
export default {
  name: 'Redirect',
  data() {
    return {};
  },
};
</script>
